/* You can add global styles to this file, and also import other style files */

* {
    box-sizing: border-box;
}
html, body
{
    height: 100%;
    min-height: 100%;
}
body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    font-family: Poppins, Roboto, sans-serif;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 10px;
}


